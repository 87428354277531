import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
  },
  {
    type: "groupHeader",
    groupTitle: "Pages",
  },
  // //!--------------static Pages list-------
  {
    id: "pages",
    title: "Pages",
    type: "item",
    icon: <Icon.Paperclip size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/pages",
  },

  {
    id: "homePage",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/home-page",
  },
  {
    id: "staticPages",
    title: "Static Pages",
    type: "collapse",
    icon: <Icon.Anchor size={15} />,
    children: [
      {
        id: "FAQS",
        title: "FAQ's",
        type: "item",
        icon: <Icon.HelpCircle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/faqs",
      },
      {
        id: "About",
        title: "About Us",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/about",
      },
      {
        id: "privacyPolicy",
        title: "Privacy Policy",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/privacy-policy",
      },
      {
        id: "TermsOfUse",
        title: "Terms Of Use",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/terms-of-use",
      },
    ],
  },

  // //!----------------amenities--------
  // {
  //   id: "amenities",
  //   title: "Amenities",
  //   type: "collapse",
  //   icon: <Icon.Star size={15} />,
  //   children: [
  //     {
  //       id: "amenitiesList",
  //       title: "Amenities List",
  //       type: "item",
  //       icon: <Icon.List size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/amenities/list",
  //     },
  //     {
  //       id: "projectAmenitiesList",
  //       title: "Project Amenities",
  //       type: "item",
  //       icon: <Icon.Star size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/project-amenities/list",
  //     },
  //   ],
  // },
  // !---------------- Proud Accomplishment--------
  // {
  //   id: "accomplishmentslist",
  //   title: "Accomplishments",
  //   type: "item",
  //   icon: <Icon.Award size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/accomplishments/list",
  // },

  //!----------------Blogs & Media--------
  {
    type: "groupHeader",
    groupTitle: "Blog",
  },
  {
    id: "blogs",
    title: "Blogs",
    type: "collapse",
    icon: <Icon.Book size={15} />,
    children: [
      {
        id: "blogList",
        title: "Blog",
        type: "item",
        icon: <Icon.AlertTriangle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/blog/list",
      },
      {
        id: "blogCategory",
        title: "Blog Category",
        type: "item",
        icon: <Icon.AlignCenter size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/blog-category/list",
      },
      // {
      //   id: "commentslist",
      //   title: "Comments List",
      //   type: "item",
      //   icon: <Icon.Users size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/comments",
      // },
      // {
      //   id: "writersList",
      //   title: "Writers",
      //   type: "item",
      //   icon: <Icon.Users size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/writers/list",
      // },
    ],
  },
  // {
  //   id: "media",
  //   title: "Media",
  //   type: "collapse",
  //   icon: <Icon.Video size={15} />,
  //   children: [
  //     {
  //       id: "medialist",
  //       title: "Media",
  //       type: "item",
  //       icon: <Icon.Video size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/media/list",
  //     },
  //     {
  //       id: "mediaCategory",
  //       title: "Media Category",
  //       type: "item",
  //       icon: <Icon.AlignCenter size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/media-category/list",
  //     },
  //     // {
  //     //   id: "commentslist",
  //     //   title: "Comments List",
  //     //   type: "item",
  //     //   icon: <Icon.Users size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/comments",
  //     // },
  //     // {
  //     //   id: "writersList",
  //     //   title: "Writers",
  //     //   type: "item",
  //     //   icon: <Icon.Users size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/writers/list",
  //     // },
  //   ],
  // },
  {
    id: "medialist",
    title: "Media",
    type: "item",
    icon: <Icon.Video size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/media/list",
  },
  //!-------------Services--------

  {
    type: "groupHeader",
    groupTitle: "Services",
  },
  {
    id: "services",
    title: "Services",
    type: "collapse",
    icon: <Icon.Clipboard size={15} />,
    children: [
      {
        id: "ServiceList",
        title: "Services List",
        type: "item",
        icon: <Icon.AlignCenter size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/services/list",
      },
      {
        id: "ServiesTypes",
        title: "Services Types",
        type: "item",
        icon: <Icon.AlignCenter size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/service-type/list",
      },
    ],
  },

  //!-------------New Tech--------

  // {
  //   id: "newTechServices",
  //   title: "New Tech Services",
  //   type: "collapse",
  //   icon: <Icon.Clipboard size={15} />,
  //   children: [
  //     {
  //       id: "NewTechList",
  //       title: "New Tech Services List",
  //       type: "item",
  //       icon: <Icon.AlignCenter size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/new-tech-services/list",
  //     },
  //     {
  //       id: "newTechType",
  //       title: "New Tech Type",
  //       type: "item",
  //       icon: <Icon.AlignCenter size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/new-tech-service-type/list",
  //     },
  //   ],
  // },
  //!-------------New Tech--------

  // {
  //   id: "morePageServices",
  //   title: "Advertising Services",
  //   type: "collapse",
  //   icon: <Icon.Clipboard size={15} />,
  //   children: [
  //     {
  //       id: "MorePageServiceList",
  //       title: "Advertising Services List",
  //       type: "item",
  //       icon: <Icon.AlignCenter size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/more-page-services/list",
  //     },
  //     {
  //       id: "MorePageServicetype",
  //       title: "More Services Type",
  //       type: "item",
  //       icon: <Icon.AlignCenter size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/more-page-service-type/list",
  //     },
  //   ],
  // },
  //!-------------Portfolio--------
  {
    id: "portfolio",
    title: "Portfolio",
    type: "collapse",
    icon: <Icon.Clipboard size={15} />,
    children: [
      {
        id: "portfolio_list",
        title: "Portfolio List",
        type: "item",
        icon: <Icon.Clipboard size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/portfolio/list",
      },
      ,
      {
        id: "Portfolio_Category_List",
        title: "Category List",
        type: "item",
        icon: <Icon.AlignCenter size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/portfolio-category/list",
      },
    ],
  },

  //!-------------Contact Us--------
  // {
  //   id: "ContactUsFormList",
  //   title: "Contact Us",
  //   type: "item",
  //   icon: <Icon.Phone size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/form-list",
  // },
  // //!-------------FAQ's--------
  // {
  //   id: "FAQS",
  //   title: "FAQ's",
  //   type: "item",
  //   icon: <Icon.HelpCircle size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/faqs",
  // },
  // //!-------------Gallery--------
  {
    type: "groupHeader",
    groupTitle: "Gallery",
  },
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/gallery",
  },
  // //!-------------Projects--------
  // {
  //   id: "ProjectsList",
  //   title: "Projects",
  //   type: "item",
  //   icon: <Icon.Clipboard size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/projects/list",
  // },
  // //!-------------Subscribers--------
  // {
  //   id: "SubscribersList",
  //   title: "Subscribers",
  //   type: "item",
  //   icon: <Icon.User size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/subscribers-list",
  // },
  //! -----------Home Page---------

  //! -----------Static Pages---------
  // {
  //   id: "staticPages",
  //   title: "Static Pages",
  //   type: "collapse",
  //   icon: <Icon.Anchor size={15} />,
  //   children: [
  //     {
  //       id: "About",
  //       title: "About Us",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/about",
  //     },
  //
  //     {
  //       id: "Delivery",
  //       title: "Delivery",
  //       type: "item",
  //       icon: <Icon.List size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/delivery",
  //     },
  //     {
  //       id: "Sales_description ",
  //       title: "Sales Description",
  //       type: "item",
  //       icon: <Icon.List size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/SalesDisc",
  //     },
  //     // {
  //     //   id: "Factories",
  //     //   title: "Factories",
  //     //   type: "item",
  //     //   icon: <Icon.Archive size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/factories",
  //     // },
  //     // {
  //     //   id: "Features",
  //     //   title: "Features",
  //     //   type: "item",
  //     //   icon: <Icon.Menu size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/features",
  //     // },
  //     // {
  //     //   id: "Sustainability",
  //     //   title: "Sustainability",
  //     //   type: "item",
  //     //   icon: <Icon.Heart size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/sustainability",
  //     // },
  //     // {
  //     //   id: "Services",
  //     //   title: "Services",
  //     //   type: "item",
  //     //   icon: <Icon.Package size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/services",
  //     // },
  //     // {
  //     //   id: "InvestmentAdvisory",
  //     //   title: "Investment Advisory",
  //     //   type: "item",
  //     //   icon: <Icon.Users size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/investment-advisory",
  //     // },
  //     // {
  //     //   id: "HolidayHomes",
  //     //   title: "Holiday Homes",
  //     //   type: "item",
  //     //   icon: <Icon.Home size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/holiday-homes",
  //     // },
  //     // {
  //     //   id: "InteriorDesigning",
  //     //   title: "Interior Designing",
  //     //   type: "item",
  //     //   icon: <Icon.PlayCircle size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/interior-designing",
  //     // },
  //     // {
  //     //   id: "ListYourProperty",
  //     //   title: "List Your Property",
  //     //   type: "item",
  //     //   icon: <Icon.List size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/list-your-property",
  //     // },
  // {
  //   id: "TermsConditions",
  //   title: "Terms & Conditions",
  //   type: "item",
  //   icon: <Icon.List size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/terms-and-conditions",
  // },
  //     // {
  //     //   id: "contact",
  //     //   title: "Contact",
  //     //   type: "item",
  //     //   icon: <Icon.Phone size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/contact",
  //     // },
  //     // {
  //     //   id: "refundPolicy",
  //     //   title: "Refund Policy",
  //     //   type: "item",
  //     //   icon: <Icon.List size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/refund-policy",
  //     // },
  //   ],
  // },

  //!--------------Pages-------

  // {
  //   id: "ContactUsFormList",
  //   title: "Form Listing",
  //   type: "item",
  //   icon: <Icon.Phone size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/form-list",
  // },
  // {
  //   id: "ClientReview",
  //   title: "Client Reviews",
  //   type: "item",
  //   icon: <Icon.Smile size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/client-review",
  // },

  //!----------------Products --------

  // {
  //   id: "Product",
  //   title: "Product",
  //   type: "item",
  //   icon: <Icon.Archive size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/product/list",
  // },
  //!----------------Variations --------

  // {
  //   id: "variationsList",
  //   title: "Variations List",
  //   type: "collapse",
  //   icon: <Icon.AlignJustify size={15} />,
  //   children: [
  //     {
  //       id: "Variation",
  //       title: "Variation",
  //       type: "item",
  //       icon: <Icon.AlignLeft size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/variation/list",
  //     },
  //     {
  //       id: "VariationValue",
  //       title: "Variation Values",
  //       type: "item",
  //       icon: <Icon.AlignLeft size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/variation-value/list",
  //     },
  //   ],
  // },

  //!----------------Country--------
  // {
  //   id: "Country",
  //   title: "Countries List",
  //   type: "item",
  //   icon: <Icon.Flag size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/country/list",
  // },
  //!----------------Category --------
  // {
  //   id: "category",
  //   title: "Category",
  //   type: "collapse",
  //   icon: <Icon.AlignJustify size={15} />,
  //   children: [
  //     {
  //       id: "Category",
  //       title: "Category",
  //       type: "item",
  //       icon: <Icon.AlignJustify size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/category/list",
  //     },
  //     {
  //       id: "SubCategory",
  //       title: "Sub Category",
  //       type: "item",
  //       icon: <Icon.AlignLeft size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/sub-category/list",
  //     },
  //     // {
  //     //   id: "ChildSubCategory",
  //     //   title: "Child Sub Category",
  //     //   type: "item",
  //     //   icon: <Icon.AlignRight size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/child-sub-category/list",
  //     // },
  //   ],
  // },
  //!----------------Brands--------
  // {
  //   id: "brands",
  //   title: "Brands List",
  //   type: "item",
  //   icon: <Icon.Award size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/brands/list",
  // },
  //!----------------Deals--------
  // {
  //   id: "deals",
  //   title: "Deals List",
  //   type: "item",
  //   icon: <Icon.CheckCircle size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/deals/list",
  // },

  // //!----------------partners--------
  // {
  //   id: "partnersList",
  //   title: "Our Partners",
  //   type: "item",
  //   icon: <Icon.Users size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/partners/list",
  // },

  // // !---------------- Media--------
  // {
  //   id: "medialist",
  //   title: "Media",
  //   type: "item",
  //   icon: <Icon.Video size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/media/list",
  // },

  // // !---------------- Media--------
  // {
  //   id: "testimoniallist",
  //   title: "Testimonials",
  //   type: "item",
  //   icon: <Icon.Star size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/testimonial/list",
  // },

  // // !---------------- Team--------
  // {
  //   id: "teamlist",
  //   title: "Our Team",
  //   type: "item",
  //   icon: <Icon.Users size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/team/list",
  // },

  // // !---------------- Project Highlights--------
  // {
  //   id: "ProjectHighlights",
  //   title: "Project Highlights",
  //   type: "item",
  //   icon: <Icon.List size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/project-highlights",
  // },

  // //!---------------Property Calculator------
  // {
  //   id: "propertycalculator",
  //   title: "Property Calculator",
  //   type: "collapse",
  //   icon: <Icon.Calendar size={15} />,
  //   children: [
  //     {
  //       id: "areas",
  //       title: "Areas",
  //       type: "item",
  //       icon: <Icon.MapPin size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/areas/list",
  //     },
  //     {
  //       id: "PropertyType",
  //       title: "Property Type",
  //       type: "item",
  //       icon: <Icon.Box size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/property-type/list",
  //     },
  //     {
  //       id: "areaPropertyCombination",
  //       title: "Area Property Combination",
  //       type: "item",
  //       icon: <Icon.Calendar size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/property-calculator/list",
  //     },
  //   ],
  // },

  // // !--------------Bookings--------
  // {
  //   id: "bookingslist",
  //   title: "Bookings",
  //   type: "item",
  //   icon: <Icon.List size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/bookings/list",
  // },

  // // !--------------Sales--------
  // {
  //   id: "saleslist",
  //   title: "Sales",
  //   type: "item",
  //   icon: <Icon.DollarSign size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/sales/list",
  // },

  // // !--------------Bookings--------
  // {
  //   id: "bookingReportlist",
  //   title: "Booking Report",
  //   type: "item",
  //   icon: <Icon.List size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/booking-report/list",
  // },

  // //!--------------Pages-------
  // {
  //   id: "InteriorQuotationList",
  //   title: "Interior Quotation List",
  //   type: "item",
  //   icon: <Icon.List size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/interior-quotation/list",
  // },
  // {
  //   id: "ListYourProertyFormList",
  //   title: "List Your Proerty Form List",
  //   type: "item",
  //   icon: <Icon.List size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/property-list",
  // },

  //!---------------Users------
  // {
  //   id: "users",
  //   title: "Registered Users",
  //   type: "item",
  //   icon: <Icon.User size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/users-list",
  // },

  //!---------------Reviews------
  // {
  //   id: "reviews",
  //   title: "Reviews",
  //   type: "item",
  //   icon: <Icon.Star size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/reviews",
  // },
  // //!---------------Header------
  // {
  //   id: "header",
  //   title: "Header",
  //   type: "item",
  //   icon: <Icon.Flag size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/header",
  // },
  // //!---------------Footer------
  // {
  //   id: "footer",
  //   title: "Footer",
  //   type: "item",
  //   icon: <Icon.CheckSquare size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/footer",
  // },

  //!------------Static Pages--------
];

export default navigationConfig;
